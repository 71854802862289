import React, { useEffect, useRef } from "react";

import { navigate } from "@reach/router";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "react-bootstrap/Button";

import videojs from "video.js";

import "./Start.sass";
import "video.js/dist/video-js.css";

const Start = () => {
  const videoSrc = "prog_index.m3u8";
  const playerRef = useRef();

  useEffect(() => {
    const player = videojs(
      playerRef.current,
      { fluid: true, autoplay: true, muted: true },
      () => {
        player.src(videoSrc);
      }
    );

    return () => {
      player.dispose();
    };
  }, []);

  const handleClick = () => {
    // TODO
    console.log("Start");

    navigate("/video");
  };

  return (
    <Container className="page">
      <Row className="text-center">
        <Col>
          <div>
            <img src="/logo.png" alt="We are Retra!" />
            <br />
            {/* TODO */}
            <div data-vjs-player>
              <video
                ref={playerRef}
                className="video-js vjs-16-9"
                playsInline
              />
            </div>
            <br />
            <Button onClick={handleClick}>Start</Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Start;
