import React from "react";

import { Location, Router } from "@reach/router";
import {
  TransitionGroup,
  CSSTransition,
  SwitchTransition
} from "react-transition-group";

import "./FadeTransitionRouter.sass";

const FadeTransitionRouter = props => (
  <Location>
    {({ location }) => (
      <TransitionGroup className="transition-group">
        <SwitchTransition>
          <CSSTransition key={location.key} classNames="fade" timeout={500}>
            {/*
              The only difference between a router animation and
              any other animation is that you have to pass the
              location to the router so the old screen renders
              the "old location"
          */}
            <Router location={location} className="router">
              {props.children}
            </Router>
          </CSSTransition>
        </SwitchTransition>
      </TransitionGroup>
    )}
  </Location>
);

export default FadeTransitionRouter;
