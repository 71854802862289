import React from "react";

import FadeTransitionRouter from "./FadeTransitionRouter";
import Video from "./Video";
import Start from "./Start";

import "./App.sass";

const App = () => (
  <FadeTransitionRouter>
    <Start path="/" />
    <Video path="video" />
  </FadeTransitionRouter>
);

export default App;
